
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ActionTemplateService from "@/core/services/ActionTemplateService";
import Swal from "sweetalert2";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import * as Yup from "yup";

export default defineComponent({
  name: "ActionTemplates",
  components: {BaseRadio, FormErrorMessage, Field, BaseForm, BaseModal, KtDatatable, QuickAction, Entities},
  setup() {
    const state = ref<any>({
      submitting: false,
      id: '',
      model: {code: '', type: '', name: '', items: [], area: 'CASE', key: ''}
    })
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Action Templates",
        [
          {link: false, router: '', text: 'List'}
        ]
      )
    })
    const page = computed(() => store.state.ActionTemplateModule.page);
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      code: Yup.string().required().max(100).label("Code"),
    });
    const filterObject = ref({});
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
      {
        name: "Type",
        key: "type",
        sortable: true,
      },
      {
        name: "Area",
        key: "area",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      ...LoadFilterObjects(Actions.LOAD_ACTION_TEMPLATES, filterObject.value, []),
      page,
      tableHeader,
      state,
      validate,
    }
  },
  methods: {
    addLine() {
      this.state.model.items.push({name: ''})
    },
    removeLine(line) {
      this.state.model.items = this.state.model.items.filter(item => item !== line)
    },
    onEdit(data: any) {
      this.state.id = data.id;
      this.state.model = {
        type: data.type,
        name: data.name,
        items: data.items,
        code: data.code,
        area: data.area,
        docKey: data.docKey
      }
      const modal = this.$refs.actionTemplateRef as typeof BaseModal
      modal.showBaseModal();
    },
    onAdd() {
      this.state.id = '';
      this.state.model = {type: 'YES_NO_REQUIRE', name: '', items: [], code: '', area: 'CASE', docKey: ''}
      const modal = this.$refs.actionTemplateRef as typeof BaseModal
      modal.showBaseModal();
    },
    onSave() {
      this.state.submitting = true;
      if (this.state.id) {
        ActionTemplateService.update(this.state.id, this.state.model).then(() => {
          this.paginationLoad()
          this.onCloseModal()
        }).finally(() => {
          this.state.submitting = false
        })
      } else {
        ActionTemplateService.create(this.state.model).then(() => {
          this.paginationLoad()
          this.onCloseModal()
        }).finally(() => {
          this.state.submitting = false
        })
      }
    },
    onCloseModal() {
      const modal = this.$refs.actionTemplateRef as typeof BaseModal
      modal.hideBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ActionTemplateService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})

